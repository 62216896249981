.hiding-element {
    opacity: 0;
    max-height: 0;
}

.opacity-quoter {
    opacity: 0.2;
}

.opacity-full {
    opacity: 1;
}

.opacity-01 {
    opacity: 0.1;
}
/* The scrollbar track (the area where the thumb moves) */
.simplebar-track.simplebar-vertical {
    width: 5px;
    background: #f0f0f000;
}

.simplebar-track.simplebar-horizontal {
    height: 5px;
    background: #f0f0f0;
}

/* The scrollbar thumb (the draggable part of the scrollbar) */
.simplebar-scrollbar::before {
    background-color: #888;
    border-radius: 4px;
}

/* Hover effect for the scrollbar thumb */
.simplebar-scrollbar:hover::before {
    background-color: #555;
}

.simplebar-content {
    display: flex;
    flex-wrap: wrap;
    width: 96%;
    padding: 5px;
}