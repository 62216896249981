

.interactive {
    pointer-events: auto;
}

.fire-priority:hover {
    opacity: 1;
}

.fire-priority.opacity-full:hover {
    opacity: 0.2;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn05 {
    0% {
        opacity: 0.5 !important;
    }
    100% {
        opacity: 1 !important;
    }
}
  


@keyframes backgroundPositionSpin {
    0% {
      background-position: top center;
    }
    100% {
      background-position: bottom center;
    }
}

.stack-card {
    width: 150px;
    height: 85px;
    /* transition: all 2s ease; */
    
}

.add-button {
    bottom: calc(-1 * var(--border-size));
    left: 0;
    z-index: -11;
    border-radius: 0 0 30px 30px;
    opacity: 0.24;
    padding-bottom: 10px;
    box-shadow: 0 2px 10px 1px rgba(255, 255, 255, 0.2);
    /* transform: translate(56px, 0px); */
    transform: translate(56px, 20px);
    pointer-events: auto;
    transition: transform 0.1s ease-out, color 0.1s ease-out, background-color 0.1s ease-out;
}

.add-button:hover {
    color: black;
    background-color: grey;
}

.drag {
    transform: translate(-9px, -14px) rotate(-45deg);
    /* transform: translate(-9px, -14px) rotate(-45deg); */
    pointer-events: none;
    z-index: -1;
    transition: transform 0.2s ease-out;
}

.get-info {
    top: calc(-1* var(--border-size));
    right: 0;
    z-index: -11;
    box-shadow: 0 2px 10px 1px rgba(255, 255, 255, 0.2);
    border-radius: 20px 20px 0 0;
    cursor: pointer;
    opacity: 0.24;
    /* transform: translate(-13px, 0); */
    transform: translate(-13px, -19px);
    pointer-events: auto;
    transition: transform 0.1s ease-out, color 0.1s ease-out, background-color 0.1s ease-out;
}

.connect-buttons-container {
    top: 13px;
    left: calc(-12px - var(--border-size));
    /* border: 1px solid white; */
    width: 15px;
    height: 60px;
    transition: transform 0.1s ease-out 0.8s;
    z-index: -11;
}

.connect-target {
    top: 3px;
    left: 0;
    /* z-index: -11; */
    box-shadow: 0 2px 10px 1px rgba(255, 255, 255, 0.2);
    border-radius: 20px 0 0 20px;
    cursor: pointer;
    opacity: 0.24;
    /* transform: translate(-13px, 15px); */
    /* transform: translate(0px, 15px); */
    pointer-events: auto;
    transition: transform 0.1s ease-out, color 0.1s ease-out, background-color 0.1s ease-out;
}


.connect-source {
    bottom: 8px;
    left: 0;
    /* z-index: -11; */
    box-shadow: 0 2px 10px 1px rgba(255, 255, 255, 0.2);
    border-radius: 20px 0 0 20px;
    cursor: pointer;
    opacity: 0.24;
    /* transform: translate(-13px, -15px); */
    /* transform: translate(0px, -20px); */
    pointer-events: auto;
    transition: transform 0.1s ease-out, color 0.1s ease-out, background-color 0.1s ease-out;
}

/* .node-card:hover .connect-buttons-container {
    transform: translate(-13px, 0px);
    transition: transform 0.1s ease-out;
} */

/* .get-info:hover + .connect-buttons-container {
    transition: transform 0.1s ease-out;
    transform: translate(0px, 0px);
} */

.connect-target:hover {
    color: black;
    background-color: grey;
}

.connect-source:hover {
    color: black;
    background-color: grey;
    z-index: 21;
}

.info-buttons-container {
    display: flex;
    justify-content: flex-end;
    bottom: 100%;
    right: -24px; /* Поруч з .get-info */
    transform-origin: bottom left;
    position: absolute;
    /* transform: rotate(180deg); */
    width: 55px;
    height: calc(55px + var(--border-size));
    /* border: 1px solid white; */
    cursor: default;
    pointer-events: none;
    transition: transform 0.2s ease-out;
    z-index: -13;
}

.delete-button, .share-button {
    /* display: none; */
    /* transform: translate(24px, -19px) rotate(0deg); */
    z-index: 0;
    opacity: 0;
    /* z-index: -1; */
    /* color: white; */
    border-radius: 20px;
    /* transition: transform 0.2s ease-out; */
}

.delete-button {
    bottom: var(--border-size);
    background-color: #ffffff30;
    transition: all 0.2s ease-out;
}

.share-button {
    top: 5px;
    right: 21px;
    transition: all 0.2s ease-out;
    box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.2);
}

.info-content {
    position: absolute;
    /* top: -100; */
    /* right: 100; */
    opacity: 0.5; /* Початкова прозорість */
    max-height: 0;
    overflow: hidden;
    padding: 0 10px;
    /* transition: right 0.2s ease-out, opacity 0.2s ease-out; */
}

.status-info-container {
    bottom: calc(100% + var(--border-size));
    left: 15px;
    /* transform: translate(15px, 0); */
    /* transform: translate(15px, 23px); */
    /* transform: translate(15px, 0px); */
    /* pointer-events: none; */
    /* border: 1px solid white; */
    border-radius: 0 60px 0 0;
    height: 40px;
    width: 110px;
    z-index: -13;
    transition: transform 0.1s ease-out, opacity 0.5s ease-out;
    cursor: default;
}

.status-info {
    bottom: 0%;
    left: 0;
    text-align: left;
    white-space: nowrap;
    /* transform: translate(0px, 25px); */
    /* text-transform: uppercase; */
    transform-origin: buttom left;
    /* text-shadow: 0 1px 1px rgba(0, 0, 0, 1); */
    border-radius: 6px 6px 0 0;
    padding: 2px 6px 0px 8px;
    /* font-size: 8; */
    /* opacity: 0; */
    opacity: 0.8;
    font-weight: 400;
    z-index: -15;
    box-shadow: 0 0 8px -2px rgba(255, 255, 255, 0.1);
}

.status-choose-container {
    display: flex;
    flex-direction: column;
    bottom: 100%;
    margin-bottom: 20px;
    left: 11px;
    font-weight: 300;
    /* border: 1px solid white; */
    /* background-color: #00000030; */
    opacity: 0;
    transition: margin-bottom 0.2s ease-out, opacity 0.1s ease-out;
    pointer-events: none;
    cursor: default;
    z-index: -15;
}

.status-choose-button {
    margin: 3px;
    padding: 2px 6px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 0.6;
}

.status-choose-button:hover {
    opacity: 0.9;
    box-shadow: 0 0 8px -2px rgba(255, 255, 255, 0.1);
}

.created-info {
    top: 0;
    left: 0;
    text-align: right;
    white-space: nowrap;
    /* text-transform: uppercase; */
    transform-origin: buttom right;
    transform: translate(30px, 17px);
    /* transform: translate(-152px, 17px); */
    /* text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.5), 1px 1px 2px rgba(255, 255, 255, 0.1); */
    border-radius: 0 6px 6px 0;
    padding: 2px 10px 0px 6px;
    opacity: 0;
    /* opacity: 1; */
    font-weight: 300;
    z-index: -12;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    box-shadow: 0 0 8px -2px rgba(255, 255, 255, 0.1);
}

.edited-info {
    top: 0;
    left: 0;
    text-align: right;
    white-space: nowrap;
    /* text-transform: uppercase; */
    transform-origin: buttom right;
    transform: translate(50px, 43px);
    /* transform: translate(-152px, 43px); */
    /* text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.5), 1px 1px 2px rgba(255, 255, 255, 0.1); */
    border-radius: 0 6px 6px 0;
    padding: 2px 10px 0px 6px;
    opacity: 0;
    /* opacity: 1; */
    font-weight: 300;
    z-index: -12;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    box-shadow: 0 0 8px -2px rgba(255, 255, 255, 0.1);
}

.due-info {
    top: 0;
    left: 0;
    text-align: left;
    white-space: nowrap;
    /* text-transform: uppercase; */
    transform-origin: buttom left;
    transform: translate(0px, 30px);
    /* transform: translate(150px, 30px); */
    /* text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.5), 1px 1px 2px rgba(255, 255, 255, 0.1); */
    border-radius: 0 6px 6px 0;
    padding: 2px 10px 0px 6px;
    opacity: 0;
    /* opacity: 1; */
    font-weight: 300;
    z-index: -12;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    box-shadow: 0 0 8px -2px rgba(255, 255, 255, 0.1);
}

.get-info:hover {
    color: black;
    background-color: grey;
}

.status-choose-container:hover {
    margin-bottom: 30px;
    opacity: 1;
    pointer-events: auto;
}

.status-info-container:hover + .status-choose-container {
    margin-bottom: 30px;
    opacity: 1;
    pointer-events: auto;
}

.get-info:hover + .status-info-container {
    transform: translate(0px, -203px);
  }

.get-info:hover ~ .created-info {
    transform: translate(calc(150px + var(--border-size)), 17px);
    opacity: 0.8;
}

.get-info:hover ~ .edited-info {
    transform: translate(calc(149px + var(--border-size)), 43px);
    opacity: 0.8;
}

.get-info:hover ~ .due-info {
    transform: translate(149px, 30px);
    opacity: 0.8;
}

.get-info:hover ~ .drag {
    transform: translate(-1px, -9px) rotate(-90deg);
}

.get-info:hover ~ .connect-buttons-container {
    transform: translate(-1px, -9px);
}

.info-buttons-container:hover {
    pointer-events: auto;
}

.info-buttons-container:hover .delete-button {
    opacity: 0.4;
}

.info-buttons-container:hover .share-button {
    opacity: 0.4;
}

.delete-button:hover {
    opacity: 0.6 !important;
}

.share-button:hover {
    color: black;
    background-color: grey;
}

.get-info:hover ~ .info-buttons-container {
    pointer-events: auto;
}

.get-info:hover ~ .info-buttons-container .delete-button {
    opacity: 0.4;
    animation: bounceIn 0.3s ease forwards;
}

.get-info:hover ~ .info-buttons-container .share-button {
    transition: opacity 0.1s ease 0.1s;
    opacity: 0.4;
    animation: bounceIn 0.3s ease forwards;
    animation-delay: 0.1s;
}

.node-card {
    transform: translate(5px, 5px);
    width: 150px;
    height: 85px;
    opacity: 0;
    pointer-events: auto;
    animation: fadeIn 1s ease forwards; 

    transition: opacity 0.2s ease-out, box-shadow 0.5s ease-out;
    /* -webkit-box-shadow: -1px 1px 56px 0px rgba(112,0,255,0.75); */
    /* -moz-box-shadow: -1px 1px 56px 0px rgba(112,0,255,0.75); */
}

.node-card.focused {
    /* transition: opacity 0.2s ease-out; */
    opacity: 1;
    animation: fadeIn05 0.5s ease forwards;
}

.node-card.isfar {
    width: fit-content;
    height: fit-content;
    border-radius: var(--border-radius) 
    /* animation: fadeIn 0.5s ease forwards; */
    /* box-shadow: -1px 1px 56px 0px rgba(112,0,255,0.75);
    -webkit-box-shadow: -1px 1px 56px 0px rgba(112,0,255,0.75);
    -moz-box-shadow: -1px 1px 56px 0px rgba(112,0,255,0.75); */
}

.node-card.isfar::before {
    z-index: -10;
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    border-radius: 1px var(--border-radius) var(--border-radius) var(--border-radius);
    background: linear-gradient(-15deg,var(--neon-first-color),var(--neon-second-color));
    background-size: 100% 200%;
    animation: backgroundPositionSpin var(--animation-speed) infinite alternate;
    animation-delay: var(--animation-delay);
}

/* .node-card.isfar::after {
    z-index: -10;
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    border-radius: var(--border-radius);
    filter: blur(var(--after-blur));
    background: linear-gradient(-15deg,var(--neon-first-color),var(--neon-second-color));
    background-size: 100% 200%;
    opacity: 0.4;
    animation: backgroundPositionSpin var(--animation-speed) infinite alternate;
    animation-delay: var(--animation-delay);
} */

.card-container::before {
    z-index: -10;
    content: "";
    position: absolute;
    inset: 0;
    left: calc(-1 * var(--border-size));
    top: calc(-1 * var(--border-size));
    display: block;
    height: var(--pseudo-element-height);
    width: var(--pseudo-element-width);
    border-radius: 4px var(--border-radius) var(--border-radius) var(--border-radius);
    background: linear-gradient(-15deg,var(--neon-first-color),var(--neon-second-color));
    background-size: 100% 200%;
    animation: backgroundPositionSpin var(--animation-speed) infinite alternate;
    animation-delay: var(--animation-delay);
}

/* .card-container::after {
    z-index: -10;
    content: "";
    position: absolute;
    inset: 0;
    left: calc(-1 * var(--border-size));
    top: calc(-1 * var(--border-size));
    display: block;
    height: var(--pseudo-element-height);
    width: var(--pseudo-element-width);
    border-radius: var(--border-radius);
    filter: blur(var(--after-blur));
    background: linear-gradient(-15deg,var(--neon-first-color),var(--neon-second-color));
    background-size: 100% 200%;
    opacity: 0.4;
    animation: backgroundPositionSpin var(--animation-speed) infinite alternate;
    animation-delay: var(--animation-delay);
} */

.stack-card-container::before {
    z-index: -10;
    content: "";
    position: absolute;
    inset: 0;
    left: calc(-1 * var(--border-size));
    top: calc(-1 * var(--border-size));
    display: block;
    height: var(--pseudo-element-height);
    width: var(--pseudo-element-width);
    border-radius: var(--border-radius);
    background: linear-gradient(-15deg,var(--neon-first-color),var(--neon-second-color));
    background-size: 100% 200%;
    animation: backgroundPositionSpin var(--animation-speed) infinite alternate;
    animation-delay: var(--animation-delay);
}



@keyframes lineACome {
    0% {
        transform: rotate(-37deg) scaleX(0);
    }
    100% {
        transform: rotate(-37deg) scaleX(1);
    }
}
@keyframes lineBCome {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.connector-line-a {
    left: -21%;
    bottom: -54%;
    position: absolute;
    height: 2px;
    width: 37.5%;
    border-radius: 2px 1px 1px 2px;
    transform-origin: top left;
    animation: lineACome 0.3s ease forwards;
    animation-delay: 0.3s;
    transform: scaleX(0);
    background-color: var(--status-secondary-color);
  }

.connector-line-b {
    left: 9%;
    bottom: -13.5%;
    position: absolute;
    height: 2px;
    width: 60%;
    border-radius: 0 2px 2px 2px;
    transform-origin: top left;
    transition: transform 0.5s ease;
    animation: lineBCome 0.3s ease forwards;
    animation-delay: 0.6s;
    transform: scaleX(0);
    background-color: var(--status-secondary-color);
}

.highlight {
    position: relative;
    display: block !important;
    z-index: 110000 !important;
    /* border: 10px solid #00ffb366; */
    border-radius: var(--border-radius) !important;
    /* filter: blur(30px); */
    box-shadow: 0 2px 60px 10px rgba(255, 255, 255, 0.4) !important;
}

.node-hover {
    z-index: 110000 !important;
    transition: opacity 0.2s ease-out, box-shadow 0.5s ease-out, transform 0.2s ease-out;
    opacity: 1 !important;
    transform: translate(50%, 50%) scale(1) !important;
    box-shadow: 0 2px 60px 10px rgba(255, 255, 255, 0.4) !important;
}

.node-hover.isVeryFar {
    opacity: 1 !important;
}

.fhighlight {
    border-radius: var(--border-radius) !important;
    box-shadow: 0 2px 60px 10px rgba(255, 255, 255, 0.4) !important;
}

.isFar.highlight{
    opacity: 1 !important;
}

.isVeryFar.highlight{
    opacity: 1 !important;
}

.isVeryFar {
    opacity: 0 !important;
    pointer-events: none;
}

.hide-blur.isVeryFar {
    opacity: 0 !important;
}

.importantView {
    opacity: 1 !important;
    pointer-events: none !important;
    transform: var(--transform) !important;
}

.waveView {
    opacity: 1 !important;
    pointer-events: none !important;
}

.isVeryFar.focused {
    opacity: 1 !important;
    pointer-events: auto !important;
}

.card-container {
    cursor: default;
    pointer-events: auto;
    border-radius: var(--card-content-radius);

    transition: width 1s ease-out, height 1s ease-out 1s;
}

.to-focus {
    width: 150px;
    height: 85px;
}

/* .card-container:hover .get-info {
    pointer-events: auto;
    transform: translate(-13px, -19px);
} */

/* .card-container:hover .status-info-container {
    pointer-events: auto;
    transform: translate(15px, 0px);
} */

.card-container:hover .add-button {
    pointer-events: auto;
    transform: translate(56px, 20px);
}

.volume-wrapper {
    z-index: 2;
    border-top-right-radius: calc(var(--card-content-radius) - 1px);
    border-bottom-right-radius: calc(var(--card-content-radius) - 1px);
    position: absolute;
    right: 0.5%;
    top: 2%;
    width: 20%;   /* Ширина області для рисочок */
    height: 96%; /* Висота області для рисочок */
    overflow: hidden;  /* Обрізає все, що виходить за межі цієї області */
    /* transition: box-shadow 0.1s, opacity 0.2s, width 0.1s; */
}

.volume-container {
    bottom: -2%;
    right: 0px;
    display: flex;

    justify-content: space-between;
    height: 103%;
    flex-direction: column-reverse; /* Щоб рисочки рендерилися знизу вгору */
    justify-content: flex-start;
    align-items: flex-end;
    /* border: 1px solid white */
}

.volume-bar {
    width: 10px;
    height: 11%;
    margin: 0.5px 0;
    box-shadow: inset -4px 1px 5px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.1s, opacity 0.2s, width 0.1s;
}

.volume-container:hover .volume-bar {
    width: 20px;
}

.volume-label {
    min-width: 50px;  /* Забезпечує достатню ширину для тексту */
    text-align: left;
    position: fixed;
    top: 40%;
    right: -13px;
    opacity: 0;
    font-size: 16px;
    font-weight: 600;
    transition: opacity 0.4s ease-out, color 0.3s;
    /* border: 1px solid white; */
    pointer-events: none;
}

.card-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    max-height: calc(1.2em * 2);
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Ensure this element has z-index */
}

.simplebar-content .stack-card {
    margin: 5px;
    /* transition: opacity 1s ease-in-out; */
}

.card-page-button{
    position: absolute;
    right: 20px;
    bottom: 5px;
    cursor: pointer;
    z-index: 222;
}

.progress-container {
    z-index: 1;
    content: "";
    position: absolute;
    bottom: 6px;
    left: 6px;
    width: 130px;
    height: 10px;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(35deg,var(--neon-first-color),var(--neon-second-color));
    background-size: 100% 200%;
    animation: backgroundPositionSpin 3s infinite alternate;
    animation-delay: var(--animation-delay);
    box-shadow: inset 0px 0px 2px #000;
    pointer-events: none;
}

.progress-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0px 0px 9px #000;
    border-radius: 10px;
    z-index: 1; /* Тінь буде позаду контейнера */
}

.status-progress,
.status-secondary-progress {
    /* opacity: 0; */
    left: -1%;
    position: relative;
    top: -38%;
    z-index: 1;
    display: flex; /* Забезпечує, щоб текст центрувався всередині */
    align-items: center;
    justify-content: center;
    height: 200%; /* Висота елемента прогресу заповнює контейнер */
    white-space: nowrap; /* Запобігає переносу тексту */
    background-size: 500% 500%;
    font-size: 9px;
    font-weight: 800;
    text-align: center;
    color:#00000099;
    text-shadow: 0px 0px 1px #888;
    animation: backgroundPositionSpin 3s infinite alternate;
    pointer-events: auto;
}

.status-secondary-progress {
    color: #00000077;
}