 .neuro-space{
    /* pointer-events: none; */
    overflow: hidden;
}

.new-link-establishing, .new-link-establishing * {
    pointer-events: none;
  }

@keyframes dashAnimation {
    to {
    stroke-dashoffset: -20;  /* Змінюємо на від'ємне значення для руху вперед */
    }
}

@keyframes dashAnimationReverse {
    to {
    stroke-dashoffset: 20;  /* Рух крапок назад */
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0.6;
        transform: scale(calc(0.3 * var(--button-scale)));
    }
    50% {
        opacity: 0.6;
        transform: scale(calc(1.1 * var(--button-scale)));
    }
    70% {
        opacity: 0.6;
        transform: scale(calc(0.9 * var(--button-scale)));
    }
    100% {
        opacity: 0.6;
        transform: scale(var(--button-scale));
        
    }
}

.line-delete-button,
.line-type-button,
.line-addnode-button,
.line-reverse-button {
    transform-origin: left top;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #ffffff30;
    opacity: 0;
    pointer-events: none;
    z-index: 111000;
}

.bounce-in {
    animation: bounceIn 0.3s ease forwards;
    transition: opacity 0.1s ease-in;
    pointer-events: auto;
}

.line-type-button.bounce-in {
    animation: bounceIn 0.3s ease forwards 0.05s;
}

.line-addnode-button.bounce-in {
    animation: bounceIn 0.3s ease forwards 0.1s;
}

.line-delete-button.bounce-in {
    animation: bounceIn 0.3s ease forwards 0.2s;
}

.bounce-in:hover{
    opacity: 1 !important;
}

.hide-blur {
    /* filter: blur(4px); */
    pointer-events: none !important; /* Вимикає взаємодію з непов'язаними елементами */
    opacity: 0.2 !important; /* Знижує видимість для більшого акценту на пов'язаних елементах */
  }

.hide-blur.line-button {
    display: none !important;
}

.new-connection-line {
    stroke-dasharray: 8, 12;  /* 0 для крапки (з кінцями закругленими), 8 для проміжку */
    stroke-linecap: round;    /* Закруглені кінці для створення крапки */
    animation: dashAnimation 1s linear infinite;
}

.new-connection-line-reverse {
    stroke-dasharray: 10, 10;  /* 0 для крапки, 8 для проміжку */
    stroke-linecap: round;
    animation: dashAnimationReverse 1s linear infinite;
}

.fit-zoom-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    top: 8vh;
    left: 2vh;
    text-align: center;
    /* transform: translate(-50%, -50%), */
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
}



.add-node-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    top: 2vh;
    left: 2vh;
    text-align: center;
    /* transform: translate(-50%, -50%), */
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
}

.see-all-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    top: 2vh;
    left: 8vh;
    text-align: center;
    /* transform: translate(-50%, -50%), */
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
}

.settings-button:hover,
.stack-button:hover,
.trash-button:hover,
.logout-button:hover,
.see-all-button:hover,
.fit-zoom-button:hover,
.add-node-button:hover {
    color: black;
    background-color: grey;
}

.logout-button {
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    top: 2vh;
    right: 2vh;
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);

}

.settings-button {
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    top: 2vh;
    right: 8vh;
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
}

.trash-button {
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    bottom: 2vh;
    right: 2vh;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
}

.stack-button {
    z-index: 11000;
    width: 4vh;
    height: 4vh;
    bottom: 2vh;
    left: 2vh;
    opacity: 0.2;
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
    transition: background-color 0.1s ease-out, color 0.1s ease-out;
}

.stack-container {
    max-width: 685px;
    min-width: 352px;
    border-radius: 20px;
    z-index: 112000;
    background-color: #111111aa;
    border: 2px solid #99999933;
    transition: width 1s ease-out, height 1s ease-out 1s, opacity 0.1s ease-in-out;
}


.stack-container.collapsed {
    bottom: 2vh;
    left: 2vh;
    width: 4vh;
    height: 4vh;
    opacity: 0;
    pointer-events: none;
    transition: bottom 0.3s ease-in 0.2s, left 0.3s ease-in 0.2s, height 0.2s ease-in 0.2s, width 0.25s ease-in 0.25s, opacity 0.1s ease-in-out 0.45s;
}

.stack-container.expanded {
    bottom: 0;
    left: 0;
    width: 98vw;
    height: 40vh;
    opacity: 1;
    pointer-events: auto;
    transition: bottom 0.3s ease-in, left 0.3s ease-in, width 0.2s ease-in, height 0.2s ease-in 0.1s, opacity 0.1s ease-in;
}
  
.cards-stack {
    height: 100%;
    width: 100%;
    position: inherit;
    left: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding: 7px;
    padding-top: 50px;
    overflow: hidden;
    align-content: flex-start;
}

.stack-close-btn.collapsed {
    display: none;
}

.cards-stack-scrollable-container {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 94%, rgba(0, 0, 0, 0) 100%);
    padding-top: 4px;
    width: 100%;
    height: 100%;
    opacity: 1;
    scroll-snap-type: mandatory y;
}

.cards-stack-scrollable-container.collapsed {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.cards-stack-scrollable-container.expanded {
    opacity: 1;
    transition: opacity 0.5s ease-in-out 0.5s;
}

.stack-close-btn {
    height: 2vh;
    width: 2vh;
    position: absolute;
    top: 3%;
    right: 2%;
    color: gray;
    box-shadow: none;
    opacity: 1;
    transition: all 1s ease-in-out 0.5s;
}

.stack-close-btn:hover {
    color: white;
}

.stack-button.expanded {
    opacity: 0;
    transition: opacity 0.1s ease-in;
    pointer-events: none;
}

.stack-button.collapsed {
    opacity: 0.2;
    transition: opacity 0.2s ease-in 0.5s;
}