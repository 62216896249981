html,
body,
#root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background: linear-gradient(135deg, #1a2a6c, #320d3b, #0b2b38); /* Можна змінити фон */
  color: #ffffff; /* Змінюємо колір тексту на білий */
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
