
html, body {
  height: 100vh;
  background: linear-gradient(155deg, #080d20, #1b141d, #04131a); /* фон подібний до графа */
}

/* Загальний контейнер, щоб центрована форма */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(135deg, #1a2a6c, #320d3b, #0b2b38); /* фон подібний до графа */
  font-family: 'Arial', sans-serif;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.1); /* Напівпрозорий фон */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);  /* М'яка тінь */
  backdrop-filter: blur(5px); /* Ефект розмиття фону */
  text-align: center;
  width: 320px;
  height: 380px;
}

.login-box.registration {
  height: 550px;
}

.login-box h2 {
  margin-bottom: 20px;
  color: #fff;
  font-weight: 300;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  color: #fff;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: all 0.2s ease-in-out;
  /* Added transition */
}

.input-group input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.input-group input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.3);
  /* Change background on focus */
  transform: scale(1.02);
  /* Scale input slightly to emphasize focus */
}

/* Hover state */
.input-group input:hover {
  background-color: rgba(255, 255, 255, 0.25);
  /* Change background on hover */
  transform: scale(1.01);
  /* Scale input slightly on hover */
}
.login-button {
  width: 80%;
  margin: 0 auto 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(155deg, #646b87, #665971, #3c3e57);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  background-size: 200% 200%;
  background-position: 0% 50%;
  transition: all 0.5s ease-in-out;
}

.login-button:hover {
  background-position: 100% 50%;
  color: #e7fbff;
  font-size: 18px;
}

.register-text {
  margin-top: 20px;
  color: #fff;
  font-size: 14px;
}

.register-text span {
  color: #ffffff4d;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

.register-text span:hover {
  color: #e7fbff;
  font-size: 17px;
}

.error-text {
  height: 30px;
  max-height: 32px;
  color: #ff774d; 
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 12px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
}