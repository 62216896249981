.initial-node {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 4;
    opacity: 1;
    transform: scale(1) rotate(0deg);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.initial-node.start-destroy {
    opacity: 0;
    transform: scale(1.5) rotate(20deg) translateY(-50px);
    filter: blur(15px);
}

.initial-node-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.initial-node-button {
    padding: 12px 2px;
    min-width: 160px;
    width: 16vw;
    margin: 0 auto;
    border: none;
    border-radius: 5px;
    background: linear-gradient(155deg, #3c4364, #322c36, #040407);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    background-position: 100% 50%;
    background-size: 200% 200%;
    transition: background-position 0.3s ease-in-out;
    box-shadow:
        0 0 15px rgba(255, 255, 255, 0.01),
        0 0 20px rgba(255, 255, 255, 0.01),
        0 0 25px rgba(255, 255, 255, 0.01),
        0 0 30px rgba(255, 255, 255, 0.01),
        0 0 40px rgba(255, 255, 255, 0.01);

}

.initial-node-button span {
    display: block;
    margin-bottom: 4px;
    background: linear-gradient(100deg, #c39c31, #c0c0c0, #c77d37);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.initial-node-center p {
    white-space: nowrap;
    position: absolute;
    transform: translate(-50%, -50%);
    top: -30px;
    left: 50%;
    text-shadow:
        0 0 3px rgba(255, 255, 255, 0.2),
        0 0 5px rgba(255, 255, 255, 0.1),
        0 0 5px rgba(255, 255, 255, 0.1),
        0 0 10px rgba(255, 255, 255, 0.1),
        0 0 30px rgba(255, 255, 255, 0.1);
    margin-top: 8px;
    font-size: 16px;
    display: block;
    display: inline-block;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(45deg, #c39c31, #c0c0c0, #c77d37, #708090, #abd8f7, #d3d3d3);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: oilAnimation 12s infinite linear;
}

.initial-node-button:hover {
    background-position: 0% 50%;
}

@keyframes oilAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}