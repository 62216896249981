.size-full {
    width: 100%;
    height: 100%;
}

.move-center-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    font-size: 16px;
    background-color: #2ecc71;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
}

.particles{
    width: 100%;
    height: 100%;
}

/* App.css */
.fade-enter {
    opacity: 0;
    position: absolute;
    width: 100%;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.fade-exit {
    opacity: 1;
    position: absolute;
    width: 100%;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

/* Ensure the body and html elements take full height and prevent jumping */

.app {
    /* position: relative; */
    min-height: 100vh;
    overflow: hidden;
}