.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(155deg, #080d20, #1b141d, #04131a);
    /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Make sure it's on top */
} 