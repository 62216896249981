@keyframes menuFadeIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backgroundPositionSpin {
    0% {
      background-position: top center;
    }
    100% {
      background-position: bottom center;
    }
}

.card-page-layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111111dd;
    z-index: 111000;
}

@keyframes openFromCard {
    0% {
        min-width: 0;
        top: var(--card-top, 50%); /* Замість цих значень вставте фактичні координати картки */
        left: var(--card-left, 50%);
        width: var(--card-width, 200px); /* Початкова ширина картки */
        height: var(--card-height, 150px); /* Початкова висота картки */
    }
    100% {
        transform: translate(-50%, -50%);
        min-width: 758px;
        width: 50vw;
        height: 98vh;
        top: 50%;
        left: 50%;
    }
}

@keyframes openFromCardFrame {
    0% {
        min-width: 0;
        top: calc(var(--card-top, 50%) - 5px); /* Замість цих значень вставте фактичні координати картки */
        left: calc(var(--card-left, 50%) - 5px);
        width: calc(var(--card-width, 200px) + 10px); /* Початкова ширина картки */
        height: calc(var(--card-height, 150px) + 10px); /* Початкова висота картки */
    }
    100% {
        transform: translate(-50%, -50%);
        min-width: 768px;
        width: calc(50vw + 10px);
        height: calc(98vh + 10px);
        top: 50%;
        left: 50%;
    }
}

.tiptap {
    :first-child {
      margin-top: 0;
    }

    p {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
  
    /* List styles */
    ul, 
    ol {
      padding: 0 1rem;
      margin: 1.25rem 1rem 1.25rem 0.4rem;
      list-style: initial;
      
      li p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }
    ul li::marker,
    ol li::marker {
    content: '●  ';
    color: var(--neon-middle-second-color); /* Основний колір */
    }

    /* Другий рівень списків */
    ul ul li::marker,
    ol ol li::marker {
    content: '○  ';
    color: var(--neon-middle-second-color); /* Інший колір */
    }

    /* Третій рівень списків */
    ul ul ul li::marker,
    ol ol ol li::marker {
    content: '●  ';
    color: var(--neon-middle-first-color)
    }

    /* Третій рівень списків */
    ul ul ul ul li::marker,
    ol ol ol ol li::marker {
    content: '○  ';
    color: var(--neon-middle-first-color)
    }

  
    /* Heading styles */
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6 {
      line-height: 1.1;
      margin-top: 2.5rem;
      text-wrap: pretty;
    }
  
    h1, 
    h2 {
      margin-top: 3.5rem;
      margin-bottom: 1.5rem;
    }
  
    h1 { 
      font-size: 1.4rem; 
    }
  
    h2 { 
      font-size: 1.2rem; 
    }
  
    h3 { 
      font-size: 1.1rem; 
    }
  
    h4, 
    h5, 
    h6 { 
      font-size: 1rem; 
    }
  
    /* Code and preformatted text styles */
    code {
      background: linear-gradient(15deg, var(--neon-weak-first-color), var(--neon-weak-second-color));
      border-radius: 0.4rem;
      color: var(--neon-second-color);
      font-size: 0.85rem;
      padding: 0.25em 0.3em;
    }
  
    pre {
      background: linear-gradient(-15deg, var(--neon-weak-first-color), var(--neon-weak-second-color));
      border-radius: 0.5rem;
      color: var(--neon-second-color);
      font-family: 'JetBrainsMono', monospace;
      margin: 1.5rem 0;
      padding: 0.75rem 1rem;
  
      code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
      }
    }
  
    blockquote {
      border-left: 3px solid var(--neon-middle-second-color);
      margin: 1.5rem 0;
      padding-left: 1rem;
    }
  
    hr {
      border: none;
      border-top: 1px solid var(--neon-second-color);
      margin: 2rem 0;
      opacity: 0.2;
    }

    /* padding: 0.75rem; */
    outline: none;
  }
  
 
  /* Floating menu */
  .floating-menu {
    display: flex;
    background-color: var(--gray-3);
    padding: 0.1rem;
    border-radius: 0.5rem;
  
    button {
      background-color: unset;
      padding: 0.275rem 0.425rem;
      border-radius: 0.3rem;
  
      &:hover {
        background-color: var(--gray-3);
      }
  
      &.is-active {
        background-color: var(--white);
        color: var(--purple);
  
        &:hover {
          color: var(--purple-contrast);
        }
      }
    }
  }

.card-page-frame {
    content: "";
    position: absolute;
    display: block;
    border-radius: 4px 25px 25px 25px;
    background: linear-gradient(-15deg, var(--neon-first-color), var(--neon-second-color));
    background-size: 100% 200%;
    animation: openFromCardFrame 0.6s ease forwards, backgroundPositionSpin 3s infinite alternate;
    opacity: 0.8;
    box-shadow: 0px 0px 10px var(--neon-middle-second-color);
}

.card-page {
    z-index: 10;
    position: relative;
    overflow: hidden;
    scrollbar-width: 15px;
    scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0);
    background: #131313;
    border-radius: 22px;
    animation: openFromCard 0.6s ease forwards;
}

.card-page::after {
    z-index: -1;
    pointer-events: none;
    content: "";
    box-shadow: inset 0px 0px 5px #000;
    position: absolute;
    border-radius: 22px;
    inset: 0; /* Займе всю площу батьківського елемента */
}

.card-page-close-button {
    pointer-events: auto;
    z-index: 10;
    position: absolute;
    top: 1%;
    right: 1.5%;
    opacity: 0.3;
    font-size: 20px;
    transition: opacity 0.2s ease;
}

.card-page-close-button:hover {
    opacity: 0.8;
}

::selection {
    background-color: #9b8ae740;
  }

.is-editor-empty:first-child::before {
    color: #343638;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.ProseMirror {
  cursor: text; /* Фікс курсора */
}


.bubble-menu {
    display: flex;
    align-items: stretch;
    height: 36px;
    background: rgb(37, 37, 37);
    overflow: hidden;
    font-size: 14px;
    line-height: 1.2;
    border-radius: 8px;
    box-shadow: rgba(255, 255, 255, 0.07) 0px 0px 0px 1px;
    padding: 4px;
    
    border: 1px solid var(--gray-1);

    svg {
        width: 16px;
        height: 16px;
        fill: #fff;
    }
  
    button {
      background-color: unset;
      padding-left: 7px;
      padding-right: 6px;
      color: rgba(255, 255, 255, 0.81);
      border-radius: 6px;
  
      &:hover {
        background-color: #ffffff0b;
      }
  
      &.is-active {
        background-color: #ffffff11;
  
        &:hover {
          background-color: #ffffff19;
        }
      }
    }
}


button {
  display: block;
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s;
}

button:hover {
  background: rgba(255, 255, 255, 0.1);
}